<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-line"></icon>
        </span>
      </div>
      <div class="d-flex jc-center body-box" style="height: 90%; font-size: 0.5rem; color: #ffc103">
        <div style="    height: 20%;
      line-height: 30px;
      font-size: xx-large;
      font-weight: 900;">
          <div>{{maindeail.outCode}}【{{maindeail.orderCode}}】下单日期：{{orderDate}} 交货日期：{{deliveryDate}}
          </div>
        </div>
        <dv-scroll-board :config="config"  />
      </div>
    </div>
  </div>
</template>

<script>
  import { findBigCutWareList } from "../../libs/axios";
  import { formatTime } from "../../utils/index.js";
  export default {
    data() {
      return {
        showindex: 0,
        maxindex: 0,
        orderDate: "",
        finishDate: "",
        mainData: [],
        maindeail: [],
        waitTime: 50000,
        config: {
          rowNum: 3, //表格行数
          header: [
            "尺码颜色",
            "订单数",
            "裁床齐套数",
            "配发数",
            "库存量",
          ],
          data: [],
          waitTime:5000,
          headerHeight:45,
          headerBGC: "#0f1325", //表头
          oddRowBGC: "#0f1325", //奇数行
          evenRowBGC: "#171c33", //偶数行
          index: false,
          columnWidth: [620, 310, 270, 270, 270],
          align: ["center"],
        },
      };
    },
    components: {},
    mounted() {
      this.init();
      setInterval(this.showData, 30000);
    },
    methods: {
      init() {
        this.selectBigOrderMainTotal();
      },
      showData() {
        if (this.showindex == this.maxindex) {
          this.showindex = 0;
        }
        this.config = [];
        this.config.header = [
        "尺码颜色",
            "订单数",
            "裁床齐套数",
            "配发数",
            "库存量",
        ];
        this.config.waitTime=5000;
        this.config.rowNum = 3;
        this.config.headerHeight = 45;
        this.config.headerBGC = "#0f1325";
        this.config.oddRowBGC = "#0f1325";
        this.config.evenRowBGC = "#171c33";
        this.config.columnWidth = [620, 310, 270, 270, 270];
        this.config.align = ["center"];
        this.$set(this.config, this.config.data, this.mainData[this.showindex].listsydatalist);
        this.config.data = this.mainData[this.showindex].listsydatalist;
        this.maindeail = this.mainData[this.showindex].orderMain;
        this.orderDate = formatTime(this.mainData[this.showindex].orderMain.orderDate, "yyyy-MM-dd");
        this.deliveryDate = formatTime(this.mainData[this.showindex].orderMain.deliveryDate, "yyyy-MM-dd");

        this.showindex++;


      },
      selectBigOrderMainTotal() {
        this.loading = true;
        findBigCutWareList().then((res) => {
          this.maxindex = res.data.length-1;
          this.mainData = res.data;
          this.loading = false;
          this.$set(this.config, this.config.data, res.data[0].listsydatalist);
          this.maindeail = this.mainData[0].orderMain;
          this.orderDate = formatTime(this.mainData[0].orderMain.orderDate, "yyyy-MM-dd");
          this.deliveryDate = formatTime(this.mainData[0].orderMain.deliveryDate, "yyyy-MM-dd");
          this.config.data = res.data[0].listsydatalist;
        });
      },
    },
  };
</script>


<style lang="scss">
  #centreRight1 {
    padding: 0.2rem;
  height: 5.725rem;
  min-width: 2.75rem;
  border-radius: 0.0625rem;
    .bg-color-black {
      height: 5.8125rem;
      border-radius: 0.125rem;
    }

    .text {
      font-size: 0.6rem;
      color: #c3cbde;
    }

    .body-box {
      border-radius: 0.125rem;
      overflow: hidden;
      font-size: 0.6rem;
    }

    .header-item {
      font-size: 0.5rem;
      text-align: center;
    }

    .row-item {
      font-size: 0.6rem;
      color: "#ffc103";
    }

    .ceil {
      color: #03ebfc;
      text-align: center;
    }
  }

  .pt-2 {
    display: none;
  }
</style>