<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center" style="    margin-top: 10px;">
          <dv-decoration-10 style="width: 23.3%; height: 0.0625rem" />
          <div class="d-flex jc-center" style="width: 53.3%">
            <dv-decoration-8
              :color="['#568aea', '#000000']"
              style="width: 1.5rem; height: 0.625rem"
            />
            <div class="title" style="width: 10rem">
              <span
                class="title-text"
                style="font-size: 44px; position: initial"
                >旭升帽业裁床配发中心</span
              >
              <!-- <dv-decoration-6
                class="title-bototm"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
                style="width:3.125rem;height:.1rem;"
              /> -->
            </div>
            <dv-decoration-8
              :reverse="true"
              :color="['#568aea', '#000000']"
              style="width: 1.5rem; height: 0.625rem"
            />
          </div>
          <dv-decoration-10
            style="width: 23.3%; height: 0.0625rem; transform: rotateY(180deg)"
          />
        </div>

        <!-- 第二行 -->


        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="center-box">
            <dv-border-box-13>
              <ordercutshow />
            </dv-border-box-13>
          </div>
          <div class="bototm-box">
            <dv-border-box-12>
              <bottomRight />
            </dv-border-box-12>
            <!-- <dv-border-box-12>
              <bottomRight />
            </dv-border-box-12> -->
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import {} from "../libs/axios";
import { formatTime } from "../utils/index.js";
import ordercutshow from "./warecutshow/ordercutshow";
import bottomRight from "./warecutshow/bottomRight";
// import bottomRight from "./warecutshow/bottomRight";
export default {
  data() {
    return {
      selectList: [], // 多选数据
      loading: true,
      dateDay: null,
      showdiv: "0",
      dateYear: null,
      dateWeek: null,
      scrollshow:"",
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      newsList: [
        {
          title: "A simple, seamless scrolling for Vue.js",
        },
        {
          title: "A curated list of awesome things related to Vue.js",
        },
      ],
      // 添加或编辑表单对象初始化数据
      lineGuids: "",
      workShopName: "",
    };
  },
  components: {
    ordercutshow,
    // centerRightDown1,
    bottomRight,
    // bottomRight,
  },
  mounted() {
    this.init();
    this.timeFn();
    this.cancelLoading();
    // this.audioPlayer();
    setInterval(this.init,120000);
  },
  methods: {
    init() {this.getWageList();},
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
     getWageList() {
      this.loading = false;
     },


  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 1,
      };
    },
  },
};
</script>

<style lang="scss">
@import "./warecutshow/index.scss";
</style>
<style lang="scss" scoped>
.seamless-warp2 {
  overflow: hidden;
  height: 100%;
  width: 100%;

}
ul.item {
    width: 800px;

  }
  li {
      float: left;
      margin-right: 100px;
    }
</style>